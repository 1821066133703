$primaryColor: #323232;
$secondaryColor: #5F5F5F;
$blackColor: #242424;
$accentColor: #ececec;
$backgroundColor: #f4f4f4;

$mobile-breakpoint: 768px;
$tablet-breakpoint: 1758px;

.fade-in-transition {
    animation-name: fade-in;
    animation-duration: 1s;
    -o-animation-name: fade-in;
    -o-animation-duration: 1s;
    -moz-animation-name: fade-in;
    -moz-animation-duration: 1s;
    -webkit-animation-name: fade-in;
    -webkit-animation-duration: 1s;
}

.hidden {
    opacity: 0;
}

@keyframes fade-in {
    0% { 
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}