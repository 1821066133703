@import "../../global.scss";

.intro {
    @media (max-width: #{$mobile-breakpoint}) {
        flex-direction: column;
        align-items: center;
    }
    .wrapper {
        position: relative;
        bottom: 30px;
        margin-top: 70px;
        overflow: visible;

        .landing-text {
            text-align: center;
            font-weight: 600;
            font-size: 1.25em;
            letter-spacing: 0.065em;
            color: $secondaryColor;
            @media (max-width: #{$mobile-breakpoint}) {
                font-size: 0.9375em;
            }
        }

        .landing-name {
            text-align: center;
            font-weight: 800;
            font-size: 5em;
            letter-spacing: 0.185em;
            color: $primaryColor;
            @media (max-width: #{$mobile-breakpoint}) {
                font-size: 3em;
            }
        }

        .scroll-down-wrapper {
            display: flex;
            justify-content: center;
            height: fit-content;
            overflow: visible;
            width: 100%;
            cursor: pointer;
            animation: scroll-down 1.5s ease-in-out infinite;
        }
        .scroll-down {
            height: 30px;
            width: 30px;
            border: 2px solid $primaryColor;
            rotate: 45deg;
            border-top: transparent;
            border-left: transparent;
        }

        @keyframes scroll-down {
            0% { 
                opacity: 0;
            }
            30% {
                opacity: 1;
            }
            60% {
                opacity: 1;
            }
            100% {
                opacity: 0;
                transform: translateY(10px);
            }
        }
    }
}