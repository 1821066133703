@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;450;500;600;900&display=swap');
@font-face {
  font-family: 'icomoon';
  src:  url('/public/fonts/icomoon.eot?91dln1');
  src:  url('/public/fonts/icomoon.eot?91dln1#iefix') format('embedded-opentype'),
    url('/public/fonts/icomoon.ttf?91dln1') format('truetype'),
    url('/public/fonts/icomoon.woff?91dln1') format('woff'),
    url('/public/fonts/icomoon.svg?91dln1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;

  background-image: url("/public/assets/Background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}