@import "../../global.scss";

#footer {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;

    a {
        margin: 50px 10px 50px 10px;
        padding: 20px;
        background-color: $accentColor;
        color: $blackColor;
        text-decoration: none;
        font-size: 2em;
        border-radius: 50%;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;

        &:hover {
            color: $secondaryColor;
            font-size: 2.5em;
        }
    }

    .icon-mail:before {
        content: "\ea86";
    }

    .icon-github:before {
        content: "\eab0";
    }

    .icon-linkedin:before {
        content: "\e900";
    }
}